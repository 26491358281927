import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "I Am Blessed With Great Mentors",
  "author": "Franchette",
  "date": "2020-01-22T08:05:43.000Z",
  "categories": ["mentorship", "Personal-Professional Growth"],
  "slug": "i-am-blessed-with-great-mentors",
  "draft": false,
  "meta_title": "I Am Blessed With Great Mentors",
  "cover": "../../images/wp_blog_images/lion-1209289_1920.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As the youngest of four children, I have always been able to look to my parents and siblings for mentorship. When most children were looking at television for their hero, I had mine in my home: Mr. W.R. Averett.  My father taught me how to be proud of who I am and how to handle the ugliness of discrimination. He gave me my foundation.`}</p>
    <p>{`My mother, Mrs. Emma Averett showed me how to be compassionate even to those who had mistreated me. In other words, she showed me strength. My siblings, Syrenthia, Brenetta and Wayne showed me the courage and how to stand out in a crowd. Without them, I would not be the person I am today. Dedication, integrity, love, loyalty and truth, God is what my mentors showed me.  And I love them for it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.5625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAYvz9MqxRR//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADESEx/9oACAEBAAEFAhXwrgbNW1ljOd9n/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEQIVH/2gAIAQEABj8CmHRKf//EABsQAQADAQADAAAAAAAAAAAAAAEAETEhUWGh/9oACAEBAAE/IedXOUQ604fINEe2j5il5Vy1L7n/2gAMAwEAAgADAAAAEODf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAEDAQE/ELiv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAEe2X/xAAcEAACAgMBAQAAAAAAAAAAAAABIQARMWGRUXH/2gAIAQEAAT8Qc69E8nkGRBgDy8uWrB9hXsBDql75AYiyU//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "lion 1209289 1920",
          "title": "lion 1209289 1920",
          "src": "/static/85c596bc301b86e60466836bc7e50c4a/eea4a/lion-1209289_1920.jpg",
          "srcSet": ["/static/85c596bc301b86e60466836bc7e50c4a/cb69c/lion-1209289_1920.jpg 320w", "/static/85c596bc301b86e60466836bc7e50c4a/c08c5/lion-1209289_1920.jpg 640w", "/static/85c596bc301b86e60466836bc7e50c4a/eea4a/lion-1209289_1920.jpg 1280w", "/static/85c596bc301b86e60466836bc7e50c4a/0f98f/lion-1209289_1920.jpg 1920w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` `}<em parentName="p">{`Image by Free-Photos from Pixabay`}</em></p>
    <p>{`Having these mentors showed me that there was nothing I could not do; impacting my career path as well as my ability to help others. I enjoy talking to and training others in order to empower them to be better and believe in themselves.`}</p>
    <p>{`I believe in never looking for a company or person to tell you your worth. No one can do that, but you. Know what your worth is and go from there. I learned a long time ago not to depend on others' compliments or encouraging words. Yes, they are good to receive but do not rely on them only. I have seen too many people wait for others to tell them their worth and the results did not end up in their favor.`}</p>
    <p>{`Believe in yourself and understand that your worth can not be measured by anyone. Your skills and experience are unique to you. When you know the difference, your career path will be easier for you to accomplish, at least it was for me it is.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      